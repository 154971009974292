$( document ).on('turbolinks:load', function(){
  const { initializeApp } = require('firebase/app');

  const developmentConfig = {
    apiKey: "AIzaSyDGmLGECUBVKyankoHlQp-Que5ZaQjLaIU",
    authDomain: "parkingapp-usuario.firebaseapp.com",
    databaseURL: "https://parkingapp-usuario.firebaseio.com",
    projectId: "parkingapp-usuario",
    storageBucket: "parkingapp-usuario.appspot.com",
    messagingSenderId: "126184626436",
    appId: "1:126184626436:web:b4a8f768f947ba35051851",
    measurementId: "G-EV9R0QE7E0"
  };

  const productionConfig = {
    apiKey: "AIzaSyCeEKP5ZUBAmG4JujesgHwTwBxzVoGhdjQ",
    authDomain: "grupo-orsan.firebaseapp.com",
    projectId: "grupo-orsan",
    storageBucket: "grupo-orsan.appspot.com",
    messagingSenderId: "877728099155",
    appId: "1:877728099155:web:fab925113992eed99c4fa6",
    measurementId: "G-G51ELZE4WD"
  };

  const loadConfig = () => {
    if (['development', 'staging'].includes(env)) return developmentConfig
    return productionConfig
  }

  const app = initializeApp(loadConfig());
  const { getAuth, signInWithPopup, GoogleAuthProvider } = require('firebase/auth')
  const auth = getAuth(app)


  const provider = new GoogleAuthProvider();

  const googleLogin = (action) => {
    signInWithPopup(auth, provider)
    .then(result => {
      let controller = '';

      const {
        user: {
          accessToken
        }
      } = result;

      if (action == 'sign-in') controller = 'session';
      if (action == 'sign-up') controller = 'user';

      $(`#${ controller }_token`).val(accessToken)
      $(`#${ controller }_email`).val('')
      $(`#${ controller }_password`).val('')
      $(`#${ controller }_form`).find('#session_email').val('')
      $(`#${ controller }_form`).find('#session_password').val('')
      $(`#${ controller }_form`).find(':submit').trigger('click')
    })
    .catch(error => {
      console.error(error)
    })
  }

  $('body').on('click', '.popup-google-btn', function() {
    const action = $(this).data('action')
    googleLogin(action)
  })

  $('body').on('submit', '#session_form', function() {
    $('.login-loader').removeClass('d-none')
    $('#login-form-inputs').addClass('d-none')
  })

  $('body').on('click', '#email-sign-in-backoffice, #backoffice-cancel-button', function() {
    $('#session_form').toggleClass('d-none')
    $('#sign-in-buttons').toggleClass('d-none')
  })

})
